import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// const routes: Routes = [
//   {
//     path: '',
//     loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
//   },
//   {
//     path: 'auth',
//     loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule)
//   },
//   {
//     path: 'user',
//     loadChildren: () => import('./user/user.module').then( m => m.UserPageModule)
//   },
//   {
//     path: 'tabs',
//     loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
//   },
//   {
//     path: 'cart',
//     loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
//   },
//   {
//     path: 'product-detail',
//     loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
//   },
//   {
//     path: 'store-list',
//     loadChildren: () => import('./store-list/store-list.module').then( m => m.StoreListPageModule)
//   },
//   {
//     path: 'tab1',
//     loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule)
//   },
// ];
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then( m => m.AboutusPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
