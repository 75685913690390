import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  constructor() {}
}
export enum ECommand {
  ping = 'ping',
  login = "login",
  orderAssigned = "orderAssigned"
}

export interface IReqModel {
    data: ISocketData;
    time: string;
    ip: string;
}

export interface ISocketData{
  command:ECommand,
  token?:string,
  time?:Date,
  data?:any
}
export interface ICart {
  storeUuid: string;
  product: IProduct
  isAddon: boolean
  qtty:number;
  value:number;
  ttqtty:number;
  ttvalue:number;
}

export interface IBase {
  id?: number;
  uuid?: string;
  isActive?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  // deletedAt?:Date;
}
export interface IProductPost {
  ownerUuid: string;
  primaryProduct: IProductDetails;
  secondaryProducts: Array<IProductDetailsCompact>;
  addons: Array<IProductDetailsCompact>;
  productType: string;
  tags: Array<string>;
  prices: Array<number>;
  min: number;
  max: number;
  average: number;
  expiry_date: Date;
  saleCode: string;
  description: string;
  district: string;
  province: string;
  storeType: string;
}
export interface IProductDetails {
  inventory: IStock, product: IProduct
}
export interface IProductInventories {
  stock: Array<IStock>, product:Array<IProduct>
}
export interface IProductDetailsCompact {
  inventUuid: string, productUuid: string
}
export interface IProduct extends IBase {
  productTypes: string;
  productCode: string;
  productSN: string;
  manufacturer: string;
  weight: string;
  name: string;
  image: string;//path[]
  description: string;
  brand: string;
  unit: string;
  sku: string;
  tags: Array<string>;
  storeType: string;
  storeUuid: string;
  moreDetail: any;
  mrp: string;
  pic?:any;
  product?:any;
}
export interface ISpec {
  color: string;
  dimensionPackage: string;
  dimensionNet: string;
  size: string;
  weight: string
}


export interface IStock extends IBase {
  productUuid: string;
  qtt: number;
  ttqtty: number;
  value: number;
  ttvalue: number;
  description: string;
  bywhom: string;
  direction: EDirection;
  expired: Date | undefined;
  ref: string;
}

export enum EDirection {
  sell = 'sell',
  defective = 'defective',
  expired = 'expired',
  lost = 'lost',
  stolen = 'stolen',
  warranty = 'warranty',
  others = 'others',


  purchase = 'purchase',
  reimport = 'reimport',
  transfer = 'transfer'
}
export interface IInventoryPS {
  primary: IStock,
  secondary: Array<IStock>
}
export interface IResponse {
   data:any;
  message: string,
  code: string,
  status: number
}
export interface IResponseInventory extends IResponse {
  data: IInventoryPS;

}
export interface IOrderAssigned {riderUuid:string,orderUuid:string,storeUuid:string}

// ===================  account  =========================
export interface IStoreConfig {
  storeUuid: string;
  rangefee: Array<IRangeFee>; //jsonb
  packagefee: Array<IPackageFee>; //jsonb
  location: IAddress; //jsonb
  paymentMethodUuid:Array<string>;
  shipmentMethodUuid:Array<string>;
}

export interface IRangeFee {
  radius: number;
  fee: number;
}
export interface IPackageFee  {
  fee: number;
  weight: number;
  length: number;
  width: number;
  height: number;
  volume: number;
}

export interface IPaymentMethod extends IBase {
  name: string;
  logo: string;
  description: string;
  feerange: Array<any>;
}
export interface IShipmentMethod extends IBase {
  name: string;
  logo: string;
  description: string;
  feerange: Array<any>;
}
export interface IAddress{
  address: string;
  road: string;
  province: string;
  district: string;
  village: string;
  phonenumber: string;
  contactdetails: string;
  lat: number;
  lng: number;
  alt: number;
  isActive: boolean;
}

export interface IResStock extends IResponse {
  data: {
    rows: Array<IStock>;
    count: number;
  }
}
export interface IProductStocks {
  stock: Array<IStock>, product:Array<IProduct>
}

export interface IOrderRiderAssign extends IBase{

  storeUuid:string;
  orderUuid:string;
  riderUuid:string;
  riderAccepted:ERiderAccepted;
  acceptedTime:Date;
  signedBy:string; // uuid
  locked:boolean;// false , after 5min ==> true, at storeowner can change only
}
export enum ERiderAccepted{
  none='none',
  yes='yes',
  no='no'
}

export interface IOrder extends IBase {
  name:string;
  description:string;
  ticketUuid:string; // for POS auto generate
  orderStatus:EOrderStatus; // enum order status
  customerName:string; //
  customerToken:string; //
  totalQuantity:number;
  totalValue:number;
  currency:ECurrency;
  paymentStatus:EPaymentStatus;
  paymentType:any;
  shipmentDetails:any;
  storeUuid:string;
  orderParent:string;
  defaultOrder:boolean;
  confirmAdjustOrder:Array<string>;
  orderAdjustDetails:any;
  signer:string;
  hashM:string;
  hashP:string;
  apptype:string;
}
export enum EPaymentStatus {
  paid = 'paid',
  partial = 'partial',
  unpaid = 'unpaid',
  debt = 'debt',
  canceled = 'canceled'
}
export enum ECurrency {
  USD = 'USD',
  LAK = 'LAK',
  THB = 'THB',
  VND = 'VND',
  EUR = 'EUR',
  CNY = 'CNY',
  JPY = 'JPY',
  KRW = 'KRW'
}
export enum EOrderStatus {
  cancel = 'cancel',
  new='new', //new order
  accepted='accepted',
  pending='pending', // waiting for store send request to rider or wait user pay order
  waiting='waiting', // waiting for delivery
  delivering = 'delivering',//4 keep monitoring after issue invoice
  delivered = 'delivered',//5 waiting for receipt after payment
  completed='completed',
  rider_accepted='rider_accepted',
  pickup='pickup', // waiting for user


  quotation = 'quotation', // 1 placed order not yet paid
  booked = 'booked', // 2  reserved is as the same as quotation
  ordered = 'ordered', //3  issue invoice
  pos = 'pos' //  6. pos type as not for online selling,
}