import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/service/profile.service';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/service/loading.service';
import { DatapassService } from 'src/service/datapass.service';
import { CachingService } from './caching.service';

export let browserRefresh = false;
declare var window;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  public userData: any;
  public appPages: any=[];
  public selectedIndex = 0;

  constructor(public profile: ProfileService,
    public caching: CachingService,
    public d: DatapassService,
    public m: LoadingService) {

    this.init();
  }

  storage: any = [];
  page:string=''

  getmodel(index) {
    window.home.slideTo(index)
  }

  routpage(n){
    if (!n) {

      this.page='Home'

      if (this.storage?.length) {
        // for (let index = 0; index < this.storage.length; index++) {
          // const e = this.storage[index];
          // this.appPages.push({ title: e.title })
        // }

      this.appPages = JSON.parse(JSON.stringify(this.storage))

      }
    }else{
      if(this.page == 'Aboutus'){
        return
      }
      this.page='Aboutus'
      this.storage = JSON.parse(JSON.stringify(this.appPages))


      this.appPages=[]

      // console.log(this.storage);
      
    }
  }

  async init() {

    const a = await this.loadpost_byproducttype_apptype('ລົດ EV');

    if (a?.length) {
      for (let index = 0; index < a.length; index++) {
        const e = a[index];
        this.appPages.push({ title: e })
      }
    }
  }

  ngOnInit(): void {
  }

  async loadpost_byproducttype_apptype(t: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.m.ontoast_fix('ກຳລັງໂຫລດຂໍ້ມູນ...');

      this.profile.selmany_by_producttype_all_kyc(t).subscribe(async res => {
        // console.log('res of sarch', res);

        setTimeout(() => {
          this.m.onDismiss_toast();
        }, 1000);

        if (res.data?.rows?.length) {

          this.d.PostArray = res.data.rows;

          for (let index = 0; index < this.d.PostArray.length; index++) {
            const element = this.d.PostArray[index];
            const aa = await this.caching.saveCachingPhoto(element.primaryProduct?.product?.image[0], new Date(element.primaryProduct?.product?.updatedAt), element.primaryProduct?.product?.id + '');
            element['pic'] = JSON.parse(aa).v.replace('data:application/octet-stream', 'data:image/jpeg');
          }

          const a = this.d.PostArray.map(v => v.primaryProduct?.product?.name)

          resolve(a)
        } else {
          this.d.PostArray = [];
          resolve(false)
        }

      }, error => {
        setTimeout(() => {
          this.m.onDismiss_toast();
        }, 1000);
        resolve(false)
        console.log('error', error);
      })
    })
  }
}
