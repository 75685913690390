import { Injectable } from '@angular/core';
import { ECommand, IOrder, IOrderAssigned, IReqModel, IResponse } from './model.service';
import { setWsHeartbeat } from "ws-heartbeat/client";
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WsAPiService {
  webSocket: WebSocket;

  public pingSubscription = new BehaviorSubject<any>(null);
  public loginSubscription = new BehaviorSubject<any>(null);
  public orderAssignedSubscription = new BehaviorSubject<IOrder>(null);
  token = '';
  constructor() {
    // console.log("sss");
    
    // this.connect();
  }
  t: any;
  connect() {
    // this.webSocket = new WebSocket('ws://localhost:21000/wsrider');
    this.webSocket = new WebSocket(environment.wsServer);
    this.webSocket.onopen = (ev) => {
      console.log('EVENT:', ev);

    }
    this.webSocket.onclose = ev => {
      if (ev)
        console.log('ERROR:', ev);
        //this.webSocket = new WebSocket('ws://localhost:6677');
        this.connect();
    }
    this.webSocket.onerror = ev => {
      console.log('ERROR:', ev);
    }
    this.webSocket.onmessage = ev => {
      try {
        const data = JSON.parse(ev.data) as IResponse;
        console.log('DATA', data.data);
        const dx = data.data;
        switch (dx.command) {
          case ECommand.ping:
            this.pingSubscription.next(dx.data)
            break;
          case ECommand.login:
            this.loginSubscription.next(dx.data)
            const d = {
              data: {
                command: ECommand.ping,
                token: this.token
              }
            } as IReqModel
            // setWsHeartbeat(this.webSocket, JSON.stringify(d), { pingInterval: 10000, pingTimeout: 20000 });
            // setInterval(()=>{
            //   this.webSocket.send( JSON.stringify(d))
            // },10000)
            console.log("sendsss", this.t)
            if (!this.t) {
              this.t = setInterval(() => {
                try {
                  // if (this.webSocket.OPEN==WebSocket.OPEN) {
                  if (this.webSocket.readyState==WebSocket.OPEN) {
                    console.log("sendsss");

                    this.webSocket.send(JSON.stringify(d))
                  }
                } catch (error) {
                  this.loggoutWS();
                  window.location.reload();
                }
              }, 10000)
            }
            break;
          case ECommand.orderAssigned:
            console.log('orderAssignedSubscription', dx.data);

            this.orderAssignedSubscription.next(dx.data)
            break;


          default:
            break;
        }
      } catch (error) {
        console.log('ERROR', error);
        this.loggoutWS();
        window.location.reload();
      }

    }
  }

  loggoutWS() {
    clearInterval(this.t);
    this.t = null;
    this.webSocket.close();
  }
  loginWS(token: string) {
    console.log(token);

    const data = {
      data: {
        command: ECommand.login,
        token: token
      }
    } as IReqModel
    this.token = token;
    this.send(data);
  }
  send(data: any) {
    const that = this;
    this.waitForSocketConnection(function () {
      that.webSocket.send(JSON.stringify(data));
    });
  }
  public retries = 1;
  waitForSocketConnection(callback) {
    const socket = this.webSocket;
    const that = this;
    setTimeout(
      function () {
        // console.log('ws ready state', socket.readyState);
        if (socket.readyState === 1) {
          // console.log("Connection is made")
          if (callback != null) {
            callback();
            that.retries = 0;
          }
        } else {
          if (that.retries > 2) {
            console.log('create a new connection');

            // that.webSocket.close();
            that.connect();
            that.retries = 0;
          } else {
            console.log("waiting for the connection...")
            that.waitForSocketConnection(callback);
          }
          that.retries++;
        }
      }, 1000); // wait 5 milisecond for the connection...
  }
}
